<template>
  <form id="frm_action">
  <div class="p-formgrid">
    <div class="p-card-field">
      
      <div class="p-field p-grid">
        <label class="p-col-fixed in-d cus-label-title">Họ và tên <span style="color:red">*</span></label>
      </div>
      <div class="p-field p-grid">
        <div class="p-col">
          <InputText v-model="fullname" id="fullname_add" type="text" class="p-col-width" placeholder="Nhập họ và tên" />
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed in-d cus-label-title">Số điện thoại <span style="color:red">*</span></label>
      </div>
      <div class="p-field p-grid">
        <div class="p-col">
          <InputText :disabled="mode=='edit'" id='phone_add' v-model="phone" type="number" class="p-col-width" placeholder="Nhập số điện thoại"/>
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed in-d cus-label-title">Email <span style="color:red"></span></label>
      </div>
      <div class="p-field p-grid">
        <div class="p-col">
          <InputText v-model="email" id="email_add" type="text" class="p-col-width" placeholder="mail@example.com" />
        </div>
      </div>
      <div class="p-field p-grid">
        <div class="p-col checkbox div-active-item">
          <Checkbox v-model="is_kol" :binary="true"/>
          <label class="p-col-fixed text-active">Là KOL </label>
        </div>
      </div>
      <div v-if="is_kol==true">
        <div class="p-field p-grid">
          <label class="p-col-fixed in-d cus-label-title">Mã giới thiệu <span style="color:red">*</span></label>
        </div>
        <div class="p-field p-grid">
          <div class="p-col">
            <InputText v-model="code" id="code_add" type="text" class="p-col-width" placeholder="Nhập mã giới thiệu" />
          </div>
        </div>
        <div class="p-field p-grid">
          <label class="p-col-fixed in-d cus-label-title">Hoa hồng được nhận (%)</label>
        </div>
        <div class="p-field p-grid">
          <div class="p-col">
            <InputNumber v-model="commission" id="commission_add" inputId="locale-indian" locale="en-IN" :minFractionDigits="2" :maxFractionDigits="2" fluid :min="0" :max="100" class="p-col-width"/>
          </div>
        </div>
      </div>
      
      <div v-if="mode=='edit'" class="p-field p-grid in-d">
        <label class="p-col-fixed cus-label-title">Mật khẩu</label>
      </div>
      <div v-else class="p-field p-grid in-d">
        <label class="p-col-fixed cus-label-title">Mật khẩu <span style="color:red">*</span></label>
      </div>
      <div v-if="mode=='edit'" class="p-field p-grid">
        <div class="p-col">
          <CPassword
            v-model="password"
            type="password"
            class="p-col-width input-text-box"
            :toggleMask="true"
            showIcon="pi pi-eye-slash"
            hideIcon="pi pi-eye"
            placeholder="Nhập mật khẩu"
          />
          <input class="input_tmp_validator" id="password_add" />
          <!-- <InputText v-model="password" id="password_add" type="password" class="p-col-width" placeholder="Nhập mật khẩu" /> -->
        </div>
      </div>
      <div v-else class="p-field p-grid">
        <div class="p-col">
          <CPassword
            v-model="password"
            type="password"
            class="p-col-width input-text-box"
            :toggleMask="true"
            showIcon="pi pi-eye-slash"
            hideIcon="pi pi-eye"
            placeholder="Nhập mật khẩu"
          />
          <input class="input_tmp_validator" id="password_add" />
          <!-- <InputText v-model="password" id="password_add" type="password" class="p-col-width" placeholder="Nhập mât khẩu" /> -->
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed in-d cus-label-title">Trạng thái</label>
      </div>
      <div class="p-field p-grid">
        <div class="p-col">
          <Dropdown id="status_id" v-model="selStatus" :options="list_status" optionLabel="label" class="p-col-width"/>
        </div>
      </div>
      <div v-if="is_forever == false" class="p-field p-grid">
        <label class="p-col-fixed in-d cus-label-title">Ngày hết hạn</label>
      </div>
      <div v-if="is_forever == false" class="p-field p-grid">
        <div class="p-col">
          <Calendar :show-time="true" :show-seconds="true" id="expiration_date" dateFormat="yy-mm-dd" :showClear="true" v-model="expiration_date" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" :showIcon="true" class="p-col-width" placeholder="Ngày hết hạn"/>
        </div>
      </div>
      <div v-if="is_forever == true" class="p-field p-grid">
        <div class="p-col">
          <InputText v-model="text_forever" type="text" class="p-col-width"/>
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed cus-label-title">Người giới thiệu</label>
      </div>
      <div class="p-field p-grid">
        <div class="p-col vd-select">
          <v-select id= "a1" :filterable="false" :clearable="true" placeholder="Chọn người giới thiệu" v-model="selUser" :options="listUser" label="label" @search="fetchOptionsUser" @input="UpdateDateExpiration" class="p-col-width">
            <span slot="no-options" @click="$refs.select.open = false">
              Không có dữ liệu
            </span>
          </v-select>
          <input class="input_tmp_validator" id="validator_list_user">
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed in-d cus-label-title">Ảnh đại diện</label>
      </div>
      <div class="p-field p-grid">
        <div class="p-col">
          <input class="p-inputtext p-component text-box p-col-width" style="background-color: #EEEEEE;" accept="image/*" name="images[]" type="file" @change="onFileSelect"/>
          <input class="input_tmp_validator" id="images">
          <div class="box-images mt-3 text-box"  v-if="images && images.length">
            <div class="item-img" v-for="(item, index) in images" :key="index" >
              <!-- <div class="button-clear" @click="onFileClear(index)"><img src="../../../assets/ic_clear.svg"/></div> -->
              <img :src="item.objectURL" width="200" height="200"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <Toolbar class="fixed-bottom-toolbar">
      <template slot="right">
        <Button
          :label="$constants.TEXT_BUTTON.BACK"
          @click="backToList()"
          class="p-button-warning"
          style="width: 120px !important;"
        />
        <Button
          v-if="mode == 'edit'"
          :label="$constants.TEXT_BUTTON.EDIT"
          @click="saveData()"
          
          style="width: 120px !important;"

        />
        <Button
          v-else
          :label="$constants.TEXT_BUTTON.ADD"
          @click="saveData()"
          
          style="width: 120px !important;"
        />
      </template>
    </Toolbar>

  </div>
  </form>
</template>

<script>
import moment from "moment-timezone";
import DataServices from '@/core/DataServices'
// import gql from "graphql-tag";
import Password from "@/components/Password";

export default {
  components: {
    CPassword: Password
  },
  props: {
    mode: String,
    model: Object,
    users: Array,
    id: String,
  },
  data() {
    return {
      username : '',
      fullname: '',
      email : '',
      phone: '',
      password: '',
      account_exist : false,
      validationErrors: {},
      formMode: this.mode,
      passwordVisible: false,
      list_status : [
        { value: 2, label: 'Đang hoạt động'},
        { value: 3, label: 'Đã khóa'},
      ],
      selStatus : { value: 2, label: 'Đang hoạt động'},
      images : [],
      imagesDeletes : [],
      is_kol : false,
      commission : null,
      code : '',
      expiration_date : null,
      yearRange : "2024:2124",
      time_zone_local : moment.tz.guess(true),
      is_forever : false,
      text_forever : 'Vĩnh viễn',
      listUserAll : [],
      listUser : [],
      selUser : null,
      parent_id_old : null,
    }
  },
  async created() {
    var year = new Date().getFullYear()
    var yearRange = '2024:'+(year+200);
    this.yearRange = yearRange+'';
  },
  async mounted() {
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      console.log("this.modelthis.model:this.model",this.model)
      this.fullname = this.model.fullname;
      this.email = this.model.email;
      this.phone = this.model.phone;
      if(this.model.type == 'kol'){
        this.is_kol = true;
        this.code = this.model.code;
      }
      this.code = this.model.code;
      this.commission = this.model.commission;
      this.is_forever = this.model.is_forever;
      if(this.model.is_forever == false){
        if(this.model.expiration_date){
          this.expiration_date = new Date(this.model.expiration_date)
        }
      }
      // this.school_name = this.model.school_name;
      // this.class_name = this.model.class_name;

      if(this.model.status == 'ACTIVE'){
        this.selStatus = { value: 'ACTIVE', label: 'Đang hoạt động'}
      }else if(this.model.status == 'BANNED'){
        this.selStatus = { value: 'BANNED', label: 'Đã cấm'}
      }
      if (!this.$commonFuction.isEmpty(this.model.relationship_file)) {
        if(this.model.relationship_file.file){
          let url = this.model.relationship_file.file.url
          let split = url.split('/')
          let fileName = split[split.length - 1]
          let obj = {'name': fileName, 'file': '', 'objectURL': url,type:'old_edit'}//type:'old_edit' nếu gặp type này ko update avatar
          this.images.push(obj)
        }
      }
      if(this.model.parent){
        this.parent_id_old = this.model.parent.id
        let tmp_parent = this.model.parent
        tmp_parent.label = tmp_parent.fullname +' - '+ tmp_parent.code
        this.selUser = tmp_parent
      }
    }
    await this.getListUser()
  },
  methods: {
    async fetchOptionsUser(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where = {
          deleted: {_eq: false},
          type : {_eq : 'kol'},
          _or :[
            {
              plain_fullname:{_like: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
            },
            {
              code : {_ilike: "%"+search.trim()+"%"},
            }
          ]
        }
        if(this.parent_id_old){
          where = {
            deleted: {_eq: false},
            _or :[
              {
                type : {_eq : 'kol'},
                _or :[
                  {
                    plain_fullname:{_like: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
                  },
                  {
                    code : {_ilike: "%"+search.trim()+"%"},
                  }
                ]
              },
              {
                id : {_eq : this.parent_id_old},
                _or : [
                  {
                    plain_fullname:{_like: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
                  },
                  {
                    code : {_ilike: "%"+search.trim()+"%"},
                  }
                ]
              }
            ]
          }
        }
        
        let {data} = await this.$apollo.query({
          query: DataServices.getList('users',{"fields" : "id fullname code"}),
          variables: {
            where_key: where,
            orderBy:{created_at: 'desc'}
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var tmp = []
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          let label = e.fullname +' - '+ e.code
          let tmp_ = e;
          tmp_.label = label
          tmp.push(tmp_)
        }
        this.listUser = tmp
      }else{
        this.listUser = this.listUserAll
      }
    },
    async getListUser(){
      var where = {
        deleted: {_eq: false},
        type : {_eq : 'kol'}
      }
      if(this.parent_id_old){
        where = {
          deleted: {_eq: false},
          _or :[
            {
              type : {_eq : 'kol'}
            },
            {
              id : {_eq : this.parent_id_old}
            }
          ]
        }
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('users',{"fields" : "id fullname code"}),
        variables: {
          where_key: where,
          orderBy:{created_at: 'desc'}
        },
        fetchPolicy: "network-only"
      })

      var list = data[Object.keys(data)];
      var tmp = []
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        let label = e.fullname +' - '+ e.code
        let tmp_ = e;
        tmp_.label = label
        tmp.push(tmp_)
      }
      // var list_courses = [];
      this.listUser = tmp
      this.listUserAll = tmp
      // this.selCourses = null;
    },
    backToList() {
      this.$emit('back');
    },

    async validateData() {
      
      let fullname_add = document.getElementById("fullname_add");
      if (this.fullname == undefined || this.fullname == null || this.fullname == ''){
        fullname_add.setCustomValidity("Vui lòng nhập họ và tên!");
      }else {
        fullname_add.setCustomValidity("");
      }
      if(this.mode == 'add'){
        let phone_add = document.getElementById("phone_add");
        if (this.phone == undefined || this.phone == null || this.phone == ''){
          phone_add.setCustomValidity("Vui lòng nhập số điện thoại!");
        }else {
          if(this.phone.length < 10){
            phone_add.setCustomValidity("Vui lòng số điện thoại ít nhất là 10 số!");
          }else if(this.phone.length > 11){
            phone_add.setCustomValidity("Vui lòng số điện thoại nhiều nhất là 11 số!")
          }else{
            //kiểm tra sdt
            let check_phone = await this.checkPhoneExist();
            if(check_phone){
              phone_add.setCustomValidity("Số điện thoại đã tồn tại");
            }else{
              phone_add.setCustomValidity("");
            }
          }
        }
      }
      let email_add = document.getElementById("email_add");
      if (this.email == undefined || this.email == null || this.email == ''){
        // email_add.setCustomValidity("Vui lòng nhập email!");
        email_add.setCustomValidity("");
      }else if(!this.validateEmail()){
        email_add.setCustomValidity("Email không đúng định dạng!");
      }else {
        // kiểm tra email tồn tại chưa
        let account_email_exist = await this.checkEmailExist();
        if(account_email_exist){
          email_add.setCustomValidity("Email đã tồn tại");
        }
        else{
          email_add.setCustomValidity("");
        }
      }
      
      if(this.is_kol == true){
        //kiểm tra mã
        let code_add = document.getElementById("code_add");
        if (this.code == undefined || this.code == null || this.code == ''){
          code_add.setCustomValidity("Vui lòng nhập mã giới thiệu!");
        }else {
          let code_exist = await this.checkCodeExist();
          if(code_exist){
            code_add.setCustomValidity("Mã giới thiệu đã tồn tại");
          }else{
            code_add.setCustomValidity("");
          }
        }
      }
      // else{
      //   code_add.setCustomValidity("");
      // }
      if(this.mode == 'add'){
        let password_add = document.getElementById("password_add");
        if (this.password == undefined || this.password == null || this.password == ''){
          password_add.setCustomValidity("Vui lòng nhập mật khẩu!");
        }else {
          if(this.password.length < 6 || this.password.length > 50){
            password_add.setCustomValidity("Mật khẩu ít nhất 6 ký tự và lớn nhất 50 ký tự!");
          }else{
            password_add.setCustomValidity("");
          }
        }
      }else{
        let password_add = document.getElementById("password_add");
        if (this.password == undefined || this.password == null || this.password == ''){
          password_add.setCustomValidity("");
        }else {
          if(this.password.length < 6 || this.password.length > 50){
            password_add.setCustomValidity("Mật khẩu ít nhất 6 ký tự và lớn nhất 50 ký tự!");
          }else{
            password_add.setCustomValidity("");
          }
        }
      }

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        inpObj.reportValidity();
        return true;
      }

    },
    validateEmail() {
      var re = /\S+@\S+\.\S+/;
      return re.test(this.email);
    },
    convertPhone(numberPhone) {
      var phone = numberPhone
      if (numberPhone[0] === '0') {
        phone = phone.substring(1);
      }
      phone = phone.replace(' ', '')
      phone = '+84' + phone
      return phone
    },
    async checkPhoneExist() {
      if (this.phone !== undefined && this.phone !== "") {
        var where = {
          phone: {'_eq': this.phone},
          // type: {'_eq': 'PHONE'},
          deleted: {'_eq': false}
        }
        if (this.model !== undefined) where = Object.assign(where, {id: {'_neq': this.model.id}})
        var {data} = await this.$apollo.query({
          query: DataServices.getList('users', {"fields" : "id, phone"}),
          variables: {
            where_key: where
          },
          fetchPolicy: "network-only"
        })
        // console.log(data)
        return data && data[Object.keys(data)].length > 0
      }
    },
    async checkEmailExist() {
      if (this.email !== undefined && this.email !== "") {
        var where = {
          email: {'_eq': this.email},
          // type: {'_eq': 'PHONE'},
          deleted: {'_eq': false}
        }
        if (this.model !== undefined) where = Object.assign(where, {id: {'_neq': this.model.id}})
        var {data} = await this.$apollo.query({
          query: DataServices.getList('users', {"fields" : "id, email"}),
          variables: {
            where_key: where
          },
          fetchPolicy: "network-only"
        })
        // console.log(data)
        return data && data[Object.keys(data)].length > 0
      }
    },
    async checkCodeExist(){
      if (this.code !== undefined && this.code !== "") {
        var where = {
          code: {'_eq': this.code},
          // deleted: {'_eq': false}
        }
        if (this.model !== undefined) where = Object.assign(where, {id: {'_neq': this.model.id}})
        var {data} = await this.$apollo.query({
          query: DataServices.getList('users', {"fields" : "id, code"}),
          variables: {
            where_key: where
          },
          fetchPolicy: "network-only"
        })
        return data && data[Object.keys(data)].length > 0
      }
    },
    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
       // this.saving = false;
        this.$commonFuction.is_loading(false);
        return
      }
      var tmp_expiration_date = null;
      if(this.expiration_date){
        var tmp_date = new Date(this.expiration_date)
        // console.log("tmp_date",tmp_date);
        var tmp_date_upd = moment.tz(tmp_date,this.time_zone_local).format('YYYY-MM-DD HH:mm:ss');
        // console.log("tmp_date_upd",tmp_date_upd);
        tmp_expiration_date = new Date(tmp_date_upd).toUTCString();
      }
      // console.log("tmp_expiration_date",tmp_expiration_date);
      var params = {
        username : this.phone,
        fullname: this.fullname,
        email: this.email,
        phone: this.phone,
        password : this.password,
        code : this.code,
        commission : this.commission,
        status : this.selStatus,
        images : this.images,
        imagesDeletes : this.imagesDeletes,
        is_kol : this.is_kol,
        expiration_date : tmp_expiration_date,
        is_forever : this.is_forever,
        parent : this.selUser,
        parent_id_old : this.parent_id_old
      }
      console.log("params", params)
      // this.$commonFuction.is_loading(false);
      this.$emit('save', params);
    
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    async onFileSelect(event) {
      let files = event.target.files || event.dataTransfer.files;
      if (files.length > 0) {
        if (files.length > 1) {
          alert("Chỉ được upload tối đa 1 ảnh");
          return;
        }
        let acceptTags = ["jpeg","jpg","gif","png","JPEG","JPG","PNG","GIF",];
        for (let i = 0; i < files.length; i++) {
          if (!acceptTags.includes(files[i].name.split(".").pop())) {
            alert(
              "Vui lòng upload ảnh có định dạng là: jpeg, jpg, gif, png!"
            );
            return;
          }
        }
        var arrTemp = []
        for (let file of files) {
          // if (file.size <= this.maxFileSize) {
            file.source = file;
            file.objectURL = window.URL.createObjectURL(file);
            arrTemp.push(file);
          // }
        }
        
        if (this.images.length > 0 && this.images[0].file === "") {
          this.imagesDeletes.push(this.images[0]);
        }
        this.images = [];
        this.images = this.images.concat.apply(this.images, arrTemp);
      }
      event.target.value = "";
      // console.log("this.images",this.images)
      return;
    },
  },

}
</script>

<style lang="scss">
  .p-multiselect {
    width: 450px;
  }
  .p-multiselect-label:not(.p-placeholder) {
    padding-top: .25rem;
    padding-bottom: .25rem;
    
  }
  .category-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .2rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
  }
  .p-multiselect-label-container{
    height: 40px;
  }
  .p-password .pi.pi-eye {
    color: #000;
    font-size: 14px;
  }

  #a1 .vs__dropdown-toggle{
    height: 40px;
  }
  
</style>

<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <formEdit
          :key="componentKey"
          :mode="mode"
          :id="id"
          @back="backToList"
          @save="saveData"
          :model="models"/>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Config from '../../../config/index'
import DataServices from '@/core/DataServices'
import formEdit from './add'
import moment from "moment-timezone";
// import md5 from 'js-md5'
export default {
  components: {
    formEdit
  },
  data() {
    return {
      validationErrors: [],
      title: "Tạo user/ KOL",
      mode: 'add',
      models: {},
      images: [],
      oldImages: [],
      componentKey: 0,
      page_transaction:null,
      id:null
    }
  },
  async mounted () {
    // console.log("this.$route.name:",this.$route.query.page_transaction);
    this.mode = this.$route.name === 'editUser' ? 'edit' : this.mode;
    console.log("this.mode",this.mode)
    this.title = this.mode === 'edit' ? 'Chỉnh sửa' : this.title;
    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_USER);
    if (this.mode == 'edit'){
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }
  },
  methods: {
    backToList() {
      this.$router.push({ path: '/admin/list-users' });
    },
    convertPhone(numberPhone) {
      var phone = numberPhone
      if (numberPhone[0] === '0') {
        phone = phone.substring(1);
      }
      phone = phone.replace(' ', '')
      phone = '+84' + phone
      return phone
    },
    async saveData(params) {
      if (params !== undefined && Object.entries(params).length > 0) {
        var objects = {
          'status': params.status.value,
          'fullname': params.fullname,
          'plain_fullname': this.$commonFuction.SkipVN(params.fullname),
          'email': params.email,
          'expiration_date' : params.expiration_date,
          // 'phone' : params.phone,
        }
        if(params.parent){
          objects.parent_id = params.parent.id
          if(this.mode === 'add'){
            objects.created_at_parent = moment().tz('Asia/Ho_Chi_Minh');
          }
        }
        if(params.parent_id_old){//có parent cũ thì cập nhật parent_id
          if(params.parent){//có chọn parent
            objects.parent_id = params.parent.id
          }else{//không chọn thì set lại là null
            objects.parent_id = null
            objects.created_at_parent = null
          }
        }else{//không có parent cũ thì thêm ngày giới thiệu
          if(params.parent){
            objects.parent_id = params.parent.id
            objects.created_at_parent = moment().tz('Asia/Ho_Chi_Minh');
          }
        }
        var that = this;
        if (this.mode === 'add') {
          objects.phone = params.phone
          if(params.is_kol == true){
            objects.code = params.code
            objects.commission = params.commission ? params.commission : 0;
            objects.type = 'kol'
          }else{
            objects.commission = 0;
            objects.type = 'user'
          }
          var dataInsert = this.$commonFuction.convertJsonObject(objects);
            var accountsInsert = {
              'account': params.username,
              'type': 'USERNAME',
              'role': 'user',
              'is_otp': 2,
              'password' : this.$commonFuction.hashPassword(params.password),
              //'plain_fullname': this.$commonFuction.SkipVN(params.fullname),
            };
            dataInsert.accounts = {data: this.$commonFuction.convertJsonObject(accountsInsert)};

            await that.$apollo
              .mutate({
                mutation: DataServices.insertData('users'),
                variables: {
                  objects: dataInsert
                }
              })
              .then(async (res)  => {
                if (res.data[Object.keys(res.data)].affected_rows) {
                  var returning = res.data[Object.keys(res.data)].returning[0];
                  // console.log("returningreturning:",returning);
                  if (params.images && params.images.length > 0){
                    that.$CoreService.uploadImages(that.$constants.FOLDER_S3.USER, params.images).then((resImage) => {
                      console.log("resresres:", resImage);
                      if (resImage) {
                        var objectImage = resImage[0];//lấy 1 tấm hình
                        that.$CoreService.updateDataImage(that.$constants.TYPE_IMAGE.USER, returning.id, objectImage).then(() => {
                          that.$commonFuction.is_loading(false);
                          that.$toast.add({severity: 'success', summary: 'Thông báo', detail: 'Thêm thành công', life: 3000});
                          that.$router.push({path: '/admin/list-users'});
                        });
                      }
                    });
                  }else{
                    this.$commonFuction.is_loading(false);
                    that.$toast.add({severity: 'success', summary: 'Thông báo', detail: 'Thêm thành công', life: 3000});
                    this.$router.push({path: '/admin/list-users'});
                  }
                }
              }).catch(async error => {
                console.log('error', error);
                that.$toast.add({
                  severity: 'error',
                  summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                  detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                  life: 3000
                });
                this.$commonFuction.is_loading(false);
                // this.summitEvent = false;
              });
        //EDIT
        } else  {
          var uid = this.$route.params.id
          var accountUpdate = {
            // 'account': params.phone
            // is_otp : 2
          }
          if(params.password){
            accountUpdate.password = this.$commonFuction.hashPassword(params.password)
          }
          if(params.is_kol == true){
            objects.code = params.code
            objects.commission = params.commission ? params.commission : 0;
            objects.type = 'kol'
          }else{
            objects.type = 'user'
          }
          var queryUpdate = `
              mutation update_users_multil($users_object: users_set_input,$accounts_object: accounts_set_input) {
                update_users(where: { id: {_eq: "${uid}"}}, _set: $users_object) {
                  affected_rows
                  returning {
                    id
                  }
                }
                update_accounts(where: { uid: {_eq: "${uid}"}}, _set: $accounts_object) {
                  affected_rows
                  returning {
                    id
                  }
                }
              }
            `;

          let variables = {
            users_object: objects,
            accounts_object: accountUpdate,
          };
          that.$apollo.mutate({
            mutation: gql(queryUpdate),
            variables: variables
          }).then(async (res) => {
            console.log(res)
            var returning = {id : this.models.id}
            if (params.images && params.images.length > 0){
              var type_image = params.images[0].type;
              //check hình ảnh có phải hình cũ ko || nếu hình cũ thì ko update
              if (type_image != 'old_edit') {
                //xóa hình ảnh record nếu có sự thay đổi
                that.$CoreService.deleteDataImage(that.$constants.TYPE_IMAGE.USER, returning.id).then((resDelete) => {
                  console.log("resresres:", resDelete);
                  that.$CoreService.uploadImages(that.$constants.FOLDER_S3.USER, params.images).then((resImage) => {
                    console.log("resresres:", resImage);
                    if (resImage) {
                      var objectImage = resImage[0];//lấy 1 tấm hình
                      that.$CoreService.updateDataImage(that.$constants.TYPE_IMAGE.USER, returning.id, objectImage).then(() => {
                        that.redirectEdit();
                      });
                    }
                  });
                });
              } else {
                that.redirectEdit();
              }
            }else{
              that.redirectEdit();
            }
          })
        }
      }
    },
    async redirectEdit() {
      var that = this;
      that.$commonFuction.is_loading(false);
      var router_back = {path: '/admin/list-users'};
      that.$router.push(router_back);
      that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Lưu dữ liệu thành công', life: 3000 })
    },
    async uploadImagesOld(userID) {
      var s3client = Config.s3Client('user')
      if (this.images && userID) {
        //xoá avatar
        if (this.oldImages.length > 0) await this.deleteAvatar(s3client);

        this.$store.commit('setLoading', true);
        var objects = null;
        for (let index = 0; index < this.images.length; index++) {
          const img = this.images[index];
          let file = img.source
          if (file !== '' && img.file !== '') {
            let fileName = img.name;
            let random = Math.random().toString().slice(2);
            fileName = `${random}_${fileName}`
            await s3client.uploadFile(file, fileName).then((data) => {
              objects = data.location;
            });
          }
        }
        // console.log(objects);
        if (objects !== null) {
          this.$apollo.mutate({
            mutation: DataServices.updateData('users'),
            variables: {
              'data_update': {
                'avatar': objects
              },
              'where_key': {
                'id': {'_eq': userID}
              }
            }
          }).then(() => {
            // console.log('UPDATE AVATAR THANH CONG')
          }).catch((error) => {
            console.error('ERROR: ', error)
          })
        }
      }
    },
    async deleteAvatar(s3client) {
      for (let i = 0; i < this.oldImages.length; i++) {
        let fileName = this.oldImages[i].name
        await s3client.deleteFile(fileName)
      }
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('users', {"fields" : `id fullname phone email role active status is_forever type code expiration_date commission accounts{type account} relationship_file {id file {id url}} parent {id fullname code}`}),
        variables: {
          where_key: {
            id: {'_eq': id}
          }
        },
        fetchPolicy: "network-only"
      })
      // console.log("datadata:",data);
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
      }
      this.componentKey++;
    },

  },
}
</script>

<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:450px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;}
      }
      .box-images{ margin-top: 10px; position: relative;
        .item-img{height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative; border: 0.5px solid #ccc;
          &.p-col-5{width: 18%;;}
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain;}
          .button-clear{width: 18px; height: 18px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 5px; padding: 0;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
</style>
<style>
.list-group-v {
  /* display: -webkit-box;
   display: -ms-flexbox;*/
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  /*-ms-flex-direction: column;*/
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item-v:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}
.list-group-item-v {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125) ;
  height: 50px;
}
.box-check-box{
  position: relative;
}
.box-check-box label{
  position: absolute;
  left: 0px;
  top: 2px;
}
.box-check-box .check-box-day{
  position: absolute;
  right: 0px;
  top: 2px;
}
.p-field {
  margin-bottom: 0.5rem;
}
</style>